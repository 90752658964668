import classes from './ScoreLine.module.css';

import { Group, Progress, Text, Tooltip, useMantineTheme } from '@mantine/core';

import { NumberFormat } from '~/utils/NumberFormat';

interface Props {
  score: number;
  tooltip?: string;
  withLabel?: boolean;
}

export function ScoreLine({ score, tooltip, withLabel = false, ...others }: Props) {
  const value = score * 100;
  const theme = useMantineTheme();

  return (
    <Group gap="xs">
      {withLabel &&
      <Text size="xs" fw={700} ta="center" className={classes.label} c={theme.other.getScoreColor(value)}>
          {NumberFormat.score(score)}
        </Text>}

      <Progress.Root radius={0} className={classes.grow}>
        <Tooltip label={tooltip} disabled={!tooltip}>
          <Progress.Section value={value} color={theme.other.getScoreColor(value)} />
        </Tooltip>
      </Progress.Root>
    </Group>);

}